<template>
  <div class="overdata">
    <div class="form form-perfect">
      <h6>联系信息</h6>
      <div class="list cf">
        <div class="form-item mar-24 fl cf">
          <label class="label fl tl"><span>*</span>公司名称：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_name"
              placeholder="请输入公司名称"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tr">公司地址：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_addr"
              placeholder="请输入公司地址"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tl"><span>*</span>主要联系人：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_contact"
              placeholder="请输入主要联系人"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tr"><span>*</span>电话：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_mobile"
              placeholder="请输入公司电话"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tl">联系人职务：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="contact_job"
              placeholder="请输入联系人职务"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tr">邮箱：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_email"
              placeholder="请输入公司邮箱"
            ></el-input>
          </div>
        </div>
      </div>
      <h6>基本信息</h6>
      <div class="list cf">
        <div class="form-item mar-24 fl cf">
          <label class="label fl tl">成立时间：</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_createtime"
              placeholder="请输入公司创建时间"
            ></el-input>
          </div>
        </div>
        <div class="form-item mar-24 fl cf">
          <label class="label fl tr">员工人数</label>
          <div class="inp-box cf fl">
            <el-input
              class="fl"
              v-model="enterprise_numbers"
              placeholder="请输入公司员工人数"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="list cf">
        <div class="l-box fl">
          <h6>合同</h6>
          <div class="cf ht-box">
            <div class="ht-hint fl"><span>*</span>保密协议签署</div>
            <div class="radio-box fl">
              <template>
                <el-radio v-model="is_secrecy" label="1">不签署</el-radio>
                <div class="cf">
                  <el-radio class="fl" v-model="is_secrecy" label="2"
                    >签署</el-radio
                  >
                  <!-- <div class="up-btn fl">上传协议</div> -->
                  <el-upload
                    v-if="is_secrecy == 2"
                    class="upload-demo fl"
                    :action="action"
                    :before-upload="beginxy"
                    v-loading="loading"
                    :data="upData"
                    :name="name"
                    :headers="headers"
                    :limit="1"
                    :on-success="upSuccess"
                    :file-list="fileList"
                  >
                    <el-button size="small" type="primary">上传协议</el-button>
                  </el-upload>
                </div>
              </template>
            </div>
          </div>
            <div class="bmxy" v-if="is_secrecy !='' " @click="downBmxy">保密协议</div>
        </div>
        <div class="r-box fl cf">
          <div class="ht-hint fl"><span>*</span>营业执照</div>
          <div class="fl">
            <div class="lisc-img img-box" v-if="license_img != ''">
              <img :src="license_img" alt="" />
            </div>
          </div>
          <div class="fl">
            <el-upload
              class="upload-demo img-box fl"
              :action="action"
              :data="upData"
              :name="name"
              :headers="headers"
              :on-remove="licenseRemove"
              :on-success="uplicense"
            >
              <div class="in-box">
                <img
                  src="../../../../../assets/images/comon_iocn_tpsc.png"
                  alt=""
                />
                <p>上传营业执照</p>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <h6><span></span>业务范围</h6>
      <div class="form-item mar-24 fl cf">
        <label class="label fl tl">业务范围：</label>
        <div class="inp-box cf fl">
          <el-input
                  class="fl"
                  v-model="business_range"
                  placeholder="请输入业务范围"
          ></el-input>
        </div>
      </div>
  <!--    <div class="table-box">
        <ul class="t-header cf">
          <li
            class="t-h-item t-width fl"
            v-for="item in fwHeader"
            :key="item.id"
          >
            {{ item.text }}
          </li>
        </ul>
        <ul class="t-b-item cf" v-for="(item, index) in htList" :key="index">
          <li class="fl t-width">{{ item.product || "无" }}</li>
          <li class="fl t-width">{{ item.area || "无" }}</li>
          <li class="fl t-width">{{ item.machine_nums || "无" }}</li>
          <li class="fl t-width">{{ item.workers || "无" }}</li>
          <li class="fl t-width">{{ item.marks || "无" }}</li>
          <li class="fl t-width">{{ item.imgs.length }}</li>
          <li class="fl t-width">
            <span class="edit-btn" @click="editHt(index, 1)">编辑</span>
            <span class="delete-btn" @click="deleteHt(index)">删除</span>
          </li>
        </ul>
      </div>
      <div class="add-btn" @click="addHt">添加业务</div>-->
      <h6 v-if="usertype == 3"><span>*</span>质量/专业</h6>
      <div class="list cf" v-if="usertype == 3">
        <div class="zl-item cf fl">
          <div class="cf">
          <span class="fl">ISO</span>
          <el-upload
            v-if="iso_img == ''"
            class="upload-demo fl"
            :action="action"
            v-loading="Isoloading"
            :data="upData"
            :name="name"
            :headers="headers"
            :limit="1"
            :before-upload="IsoFile"
            :on-success="upIso"
            :show-file-list="false"
          >
            <div class="up-btn">
              <p>请上传证书</p>
            </div>
          </el-upload>
          <div v-else class="su-box cf fl">
            <img
              class="sul-icon fl"
              src="../../../../../assets/images/zc_iocn_wj.png"
              alt=""
            />
            <div class="img-name fl">{{ IsoName }}</div>
            <img
              @click="deleteImg('IsoName')"
              class="sur-icon fr"
              src="../../../../../assets/images/zc_iocn_sc.png"
              alt=""
            />
          </div>
          </div>
        <img class="zlzy-img" :src="iso_img" alt="">
        </div>
        <div class="zl-item cf fl">
          <div class="cf">
            <span class="fl">3C</span>
            <el-upload
              v-if="three_img == ''"
              class="upload-demo fl"
              :action="action"
              v-loading="Tcloading"
              :data="upData"
              :name="name"
              :headers="headers"
              :limit="1"
              :before-upload="TcFile"
              :on-success="upTc"
              :show-file-list="false"
            >
              <div class="up-btn">
                <p>请上传证书</p>
              </div>
            </el-upload>
            <div v-else class="su-box cf fl">
              <img
                class="sul-icon fl"
                src="../../../../../assets/images/zc_iocn_wj.png"
                alt=""
              />
              <div class="img-name fl">{{ TcName }}</div>
              <img
                @click="deleteImg('TcName')"
                class="sur-icon fr"
                src="../../../../../assets/images/zc_iocn_sc.png"
                alt=""
              />
            </div>
          </div>
          <img class="zlzy-img" :src="three_img" alt="">
        </div>
        <div class="zl-item cf fl">
          <div class="cf">
          <span class="fl">RoHS</span>
          <el-upload
            v-if="rohs_img == ''"
            class="upload-demo fl"
            :action="action"
            v-loading="Roloading"
            :data="upData"
            :name="name"
            :headers="headers"
            :limit="1"
            :before-upload="roHsFile"
            :on-success="upRohs"
            :show-file-list="false"
          >
            <div class="up-btn">
              <p>请上传证书</p>
            </div>
          </el-upload>
          <div v-else class="su-box cf fl">
            <img
              class="sul-icon fl"
              src="../../../../../assets/images/zc_iocn_wj.png"
              alt=""
            />
            <div class="img-name fl">{{ RohsName }}</div>
            <img
              @click="deleteImg('RohsName')"
              class="sur-icon fr"
              src="../../../../../assets/images/zc_iocn_sc.png"
              alt=""
            />
          </div>
          </div> <img class="zlzy-img" :src="rohs_img" alt="">

        </div>
        <div class="zl-item cf fl">
          <div>
          <span class="fl">CE</span>
          <el-upload
            v-if="ce_img == ''"
            class="upload-demo fl"
            :action="action"
            v-loading="Celoading"
            :data="upData"
            :name="name"
            :headers="headers"
            :limit="1"
            :before-upload="ceFile"
            :on-success="upCe"
            :show-file-list="false"
          >
            <div class="up-btn">
              <p>请上传证书</p>
            </div>
          </el-upload>
          <div v-else class="su-box cf fl">
            <img
              class="sul-icon fl"
              src="../../../../../assets/images/zc_iocn_wj.png"
              alt=""
            />
            <div class="img-name fl">{{ CeName }}</div>
            <img
              @click="deleteImg('CeName')"
              class="sur-icon fr"
              src="../../../../../assets/images/zc_iocn_sc.png"
              alt=""
            />
          </div>
          </div>
          <img class="zlzy-img" :src="ce_img" alt="">
        </div>
      </div>
      <div class="next-btn mar-bot" @click="perfectInformation">提交</div>
    </div>
    <div class="mantle" v-if="mantle">
      <div class="add-box">
        <div class="add-inbox">
          <img
            @click="close"
            class="close"
            src="../../../../../assets/images/common_iocn_guanbi.png"
            alt=""
          />
          <div class="h6">添加业务</div>
          <div class="inp-box cf">
            <div class="fl inp-item">
              <p class="lable-text">加工门类/产品种类</p>
              <input
                type="text"
                placeholder="请输入产品种类"
                v-model="product"
              />
            </div>
            <div class="fl inp-item">
              <p class="lable-text">厂房面积</p>
              <input type="text" placeholder="请输入厂房面积" v-model="area" />
            </div>
            <div class="fl inp-item">
              <p class="lable-text">机器数量</p>
              <input
                type="text"
                placeholder="请输入机器数量"
                v-model="machine_nums"
              />
            </div>
            <div class="fl inp-item">
              <p class="lable-text">工人数量</p>
              <input
                type="text"
                placeholder="请输入工人数量"
                v-model="workers"
              />
            </div>
            <div class="fl inp-item">
              <p class="lable-text">备注</p>
              <input type="text" placeholder="请输入备注" v-model="marks" />
            </div>
          </div>
          <p class="up-text">照片</p>
          <div class="cf up-box">
            <div
              class="photo-item fl"
              v-for="(item, index) in photoList"
              :key="index"
            >
              <img class="photo" :src="item" alt="" />
              <div class="delete-box" @click="deletePhoto(index)">
                <img
                  class="delete-icon"
                  src="../../../../../assets/images/zc_iocn_sc.png"
                  alt=""
                />
              </div>
            </div>
            <el-upload
              class="upload-demo fl"
              :action="action"
              v-loading="ywloading"
              :data="upData"
              :name="name"
              :headers="headers"
              :limit="9"
              :before-upload="begin"
              :on-success="upPhoto"
              :show-file-list="false"
            >
              <div class="in-box">
                <img
                  src="../../../../../assets/images/comon_iocn_tpsc.png"
                  alt=""
                />
              </div>
            </el-upload>
          </div>
          <button class="bc-btn" @click="bc">保存</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
// import store from "../../../store/index";
export default {
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>