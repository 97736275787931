export default {
    data() {
        return {
            action: this.url + '/api/base/personalUpload.html',
            fileList: [],
            name: "upfile",
            headers: {
                // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            upData: {
                token: localStorage['yhtoken'],
            },
            IsoName: '',
            TcName: '',
            RohsName: '',
            CeName: '',
            Isoloading: false,
            Tcloading: false,
            Roloading: false,
            Celoading: false,
            loading:false,
            ywloading:false,
        };
    },
    created(){
        this.token = localStorage['yhtoken']
    },
    methods: {
        beginxy:function(){

        },
        begin:function(){
            this.ywloading = true;
        },
        upSuccess: function (res) {
            console.log(res)
            this.loading = false
            if(res.code == 200){
                this.secrecy_img = res.data.image_url || '';
            }else{
                this.secrecy_img  = '';
                return this.$message('上传失败，请稍后重试'); 
            }

        },
        uplicense: function (res) {
            this.loading = false
            console.log(res);
            if(res.code == 200){
                this.license_img = res.data.image_url || '';
            }else{
                return this.$message('上传失败，请稍后重试'); 
            }
           
        },
        licenseRemove(file) {
            this.license_img = '';
        },
        IsoFile: function (file) {
            console.log(file.name)
            this.Isoloading = true
            this.IsoName = file.name || '';
        },
        deleteImg: function (val) {
            console.log(val)
            switch (val) {
                case 'IsoName':
                    this.IsoName = '';
                    this.iso_img = ''
                    break;
                case 'TcName':
                    this.TcName = '';
                    this.three_img = ''
                    break;
                case 'RohsName':
                    this.RohsName = '';
                    this.rohs_img = ''
                    break;
                case 'CeName':
                    this.CeName = '';
                    this.ce_img = ''
                    break;
                default:
                    break;
            }
        },
        upIso: function (res) {
            this.Isoloading = false
            if(res.code == 200){
                this.iso_img = res.data.image_url || ''; 
            }else{
                return this.$message('上传失败，请稍后重试'); 
            }
          
        },
        TcFile: function (file) {
            this.Tcloading = true
            this.TcName = file.name || '';
        },
        upTc: function (res) {
            this.Tcloading = false
            if(res.code == 200){
                this.three_img = res.data.image_url || '';
            }else{
                return this.$message('上传失败，请稍后重试'); 
            }

        },
        roHsFile: function (file) {
            this.Roloading = true
            this.RohsName = file.name || '';
        },
        upRohs: function (res) {
            this.Roloading = false
            if(res.code == 200){
                this.rohs_img = res.data.image_url || '';
            }else{
                return this.$message('上传失败，请稍后重试'); 
            }
          
        },
        ceFile: function (file) {
            this.Celoading = true
            this.CeName = file.name || '';
        },
        upCe: function (res) {
            this.Celoading = false
            if(res.code == 200){
                this.ce_img = res.data.image_url || ''
            }else{
                return this.$message('上传失败，请稍后重试'); 
            }
;
        }
    }
}