export default{
    data: function () {
        return {
            product:'',
            area:'',
            machine_nums:'',
            workers:'',
            marks:'',
            fileList:[],
            htList:[],
            photoList:[],
            mantle:false,
            type:0,    //0添加，1编辑
            index:0,
            
        }
    },
    methods:{ 
        upPhoto:function(res,fileList){
            console.log(res,1,fileList)
            this.ywloading = false;
            this.photoList.push(res.data.image_url || '')
        },
        deletePhoto:function(index){
            this.photoList.splice(index,1)
        },
        bc:function(){
            let obj = {
                product:'',
                area:'',
                machine_nums:'',
                workers:'',
                marks:'',
                imgs:[],
            };
            let photoList = this.photoList ;

            obj.product = this.product;
            obj.area = this.area;
            obj.machine_nums = this.machine_nums;
            obj.workers = this.workers;
            obj.marks = this.marks;
            obj.imgs = photoList;
            if(this.type){
                this.htList[this.index]  = obj;
            }else{
                this.htList.push(obj); 
            }
            this.product = '';
            this.area =  '';
            this.machine_nums =  '';
            this.workers =  '';
            this.marks =  '';
            this.photoList =  '';
            this.mantle = false;
        },
        close:function(){
            this.mantle = false;
        },
        addHt:function(){
            this.mantle = true;
        },
        editHt:function(index,type){
            this.mantle = true;
            this.product = this.htList[index].product;
            this.area = this.htList[index].area;
            this.machine_nums = this.htList[index].machine_nums;
            this.workers = this.htList[index].workers; 
            this.marks = this.htList[index].marks;
            this.photoList = this.htList[index].imgs;
            this.index = index;
            this.type = type
            console.log(this.fileList)

        },
        deleteHt:function(index){
            this.htList.splice(index,1)
        }
    }
}