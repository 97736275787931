import data from "../data/data"
export default {
    data() {
        return {
            fwHeader: data.fwHeader,
            usertype: 3,
        }
    },
    created() {
        this.setData();
    },
    methods: {
        setData: function () {
            let userInfo = JSON.parse(localStorage['user']);
            console.log(userInfo, 123123);
                this.enterprise_name = userInfo.enterprise_name
                this.enterprise_mobile = userInfo.enterprise_mobile
                this.enterprise_addr = userInfo.enterprise_addr
                this.enterprise_email = userInfo.enterprise_email
                this.enterprise_contact = userInfo.enterprise_contact
                this.contact_job = userInfo.contact_job
                this.enterprise_createtime = userInfo.enterprise_createtime
                this.enterprise_numbers = userInfo.enterprise_numbers
                // this.license_img = userInfo.license_img
                this.usertype = userInfo.user_type
                let business_info = userInfo.business_info
                this.license_img = userInfo.license_img;
                this.three_img = userInfo.three_img;
                this.iso_img = userInfo.iso_img;
                this.rohs_img =userInfo.rohs_img;
                this.ce_img =userInfo.ce_img;
                this.secrecy_img = userInfo.secrecy_img;
                this.is_secrecy = userInfo.secrecy_img == "" ? '1':'2'
                this.business_range = userInfo.business_range;
                for(let i = 0;i< business_info.length;i++){
                    let obj = {
                        product:'',
                        area:'',
                        machine_nums:'',
                        workers:'',
                        marks:'',
                        imgs:[],
                    };

                    obj.product = business_info[i].category_name;
                    obj.area = business_info[i].plant_area;
                    obj.machine_nums = business_info[i].parseJSON;
                    obj.workers = business_info[i].workers_nums;
                    obj.marks = business_info[i].marks;
                    obj.imgs = JSON.parse(business_info[i].imgs);
                    if(this.type){
                        this.htList[this.index]  = obj;
                    }else{
                        this.htList.push(obj); 
                    }
                }
                
        },
        downBmxy:function(){
            console.log(this.secrecy_img)
            window.location.href=this.secrecy_img;    
        }
    }
}