export default {
    data: function () {
        return {
            tzsecond: 3,
            enterprise_name: '',
            enterprise_mobile: '',
            enterprise_addr: '',
            enterprise_email: '',
            enterprise_contact: '',
            contact_job: '',
            enterprise_createtime: '',
            enterprise_numbers: '',
            license_img: '',
            business_info: '',
            secrecy_img: '',
            three_img: '',
            iso_img: '',
            rohs_img: '',
            ce_img: '',
            is_secrecy: '1',
            business_range:''
        }
    },
    methods: {
        perfectInformation: function () {
            // if(this.license_img == ''){
            //     return this.$message('营业执照不能为空');
            // }
            let formData = {
                enterprise_name: this.enterprise_name,
                enterprise_mobile: this.enterprise_mobile,
                enterprise_addr: this.enterprise_addr,
                enterprise_email: this.enterprise_email,
                enterprise_contact: this.enterprise_contact,
                contact_job: this.contact_job,
                enterprise_createtime: this.enterprise_createtime,
                enterprise_numbers: this.enterprise_numbers,
                license_img: this.license_img,
                business_info: JSON.stringify(this.htList),
                secrecy_img: this.secrecy_img,
                three_img: this.three_img,
                iso_img: this.iso_img,
                rohs_img: this.rohs_img,
                ce_img: this.ce_img,
                is_secrecy: this.is_secrecy,
                token: true,
                business_range:this.business_range,
            }
            this.$post('/api/user/perfectInformation.html', formData).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.htList = [];
                    localStorage.setItem('user',JSON.stringify(res.data))
                    this.setData();
                    this.$store.commit('changeLogin',1)
                } else {
                }
                return this.$message(res.msg);
            }).finally(() => {

            })
        },
        toIndex:function(){
            this.$router.replace('/')
        }
    },
}